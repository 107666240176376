import {
  Box,
  Grid,
  Step,
  Table,
  Paper,
  Button,
  Stepper,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TextField,
  StepLabel,
  makeStyles,
  Typography,
  StepContent,
  FormControl,
  ButtonGroup,
  TableContainer,
  CircularProgress,
} from "@material-ui/core"
import axios from "axios"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import { useSnackbar } from "notistack"
import Sigin from "../components/signin"
import { useTranslation } from "react-i18next"
import SiteContext from "../context/siteContext"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"
import LocalOfferIcon from "@material-ui/icons/LocalOffer"
import BookImage from "../../static/images/book-image.png"
import React, { useState, useEffect, useRef, useContext } from "react"
import ShoppingCartIcon from "../../static/images/Icons/ShoppingCart.svg"
import { CheckoutWzard } from "../components/cart"
import WizardStepContent from "../components/cart/cartWizard"
import Alert from "@material-ui/lab/Alert"

const CartItems = ({ pageContext }) => {
  const timerRef = useRef()
  const classes = useStyles()
  const { pLang, paymentOptions } = pageContext
  const validateFormChild = useRef(null)
  const [t, i18n] = useTranslation()
  const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  const steps = [
    t("cartItems.yourOrder"),
    t("breadcrumbs.checkout"),
    t("checkout.paymentDetails"),
    t("cartItems.success"),
  ]
  const { enqueueSnackbar } = useSnackbar()
  const [couponCode, setCouponCode] = useState()
  const [totalPrice, setTotalPrice] = useState(0)
  const [activeStep, setActiveStep] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [shippingCost, setShippingCost] = useState(0)
  const [payType, setPayType] = useState("credit_card")
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [discountPrice, setDiscountPrice] = useState(0)
  const [currencyRateState, setCurrencyRate] = useState(1)
  const [loadingContent, setLoadingContent] = useState(true)
  const [cartItemContent, setCartItemContent] = useState([])
  const [disabledBtnCoupon, setDisabledBtnCoupon] = useState(false)
  const [checkoutFormPhone, setCheckoutFormPhone] = useState()
  const [checkoutForm, setCheckoutForm] = useState({
    city: "",
    state: "",
    country: "",
    zipCode: "",
    yourNotes: "",
    yourAddress: "",
    emailAddress: "",
    firstName: "",
    lastName: "",
  })
  const [checkoutPaymentForm, setCheckoutPaymentForm] = useState({
    cardName: "",
    cardNumber: "",
    cardCVV: "",
    cardEndMonth: "",
    cardEndYear: "",
  })
  const [errorMassege, setErrorMassege] = useState({
    city: false,
    cityText: "",
    state: false,
    stateText: "",
    country: false,
    countryText: "",
    zipCode: false,
    zipCodeText: "",
    yourNotes: false,
    yourNotesText: "",
    phoneNumber: true,
    phoneNumberText: "",
    emailAddress: false,
    emailAddressText: "",
    addressTitle: false,
    addressTitleText: "",
    yourAddress: false,
    yourAddressText: "",
    cardEndYear: false,
    cardEndYearText: "",
    cardEndMonth: false,
    cardEndMonthText: "",
    cardCVV: false,
    cardCVVText: "",
    cardNumber: false,
    cardNumberText: "",
    cardName: false,
    cardNameText: "",
    couponCode: false,
    couponCodeText: "",
    couponCode: false,
    couponCodeText: "",
  })
  const { setIsActiveCart, setCartItemContentLenght } = useContext(SiteContext)

  const stepperRef = useRef(null)

  const executeScroll = () => stepperRef.current.scrollIntoView({ behavior: "smooth" })

  const handleBackStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    executeScroll()
  }

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, "cart-items", `/${i18n.language}/cart-items`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(pLang, "cart-items", `/${i18n.language}/cart-items`)
      } else {
        documentGlobal.location.href = `/${i18n.language}/cart-items`
      }
    })
  }, [i18n, pLang])

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
  }, [])

  const currencyIcon = currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>

  useEffect(
    () => () => {
      clearTimeout(timerRef.current)
    },
    []
  )

  const breadcrumbs = [
    {
      text: t("cartItems.pageTitle"),
    },
  ]

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  useEffect(() => {
    setIsActiveCart(cartItemContent.length > 0 ? true : false)
    setCartItemContentLenght(cartItemContent.length)
  }, [cartItemContent])

  const fetchCartData = () => {
    if (jwtForProfile) {
      axios
        .get(`${process.env.GATSBY_API_URL}/get-cart-items-by-type/paper`, {
          headers: {
            Authorization: `Bearer ${jwtForProfile}`,
            "Accept-Language": i18n.language,
          },
        })
        .then(res => {
          setCartItemContent(res.data.items)
          setCartItemContentLenght(res.data.items.length)
          setLoadingContent(false)
        })
    }
  }

  useEffect(() => {
    fetchCartData()
  }, [i18n, jwtForProfile])

  useEffect(() => {
    setTotalPrice(
      cartItemContent
        .map(item =>
          item.couponeDiscountedPrice > 0 ? item.couponeDiscountedPrice * item.quantity : item.couponeDiscountedPrice === 0 ? 0 :
          !item.discountedPrice > 0
            ? item.discountPrice > 0
              ? item.discountPrice * item.quantity
              : item.price * item.quantity
            : item.discountedPrice
        )
        .reduce((a, b) => a + b, 0) + shippingCost
    )
    // item.couponeDiscountedPrice &&
    setDiscountPrice(
      cartItemContent
        .map(
          item =>
            (item.couponeDiscountedPrice > 0
              ? item.itemPrice - item.couponeDiscountedPrice : item.couponeDiscountedPrice === 0 ? item.itemPrice
              : item.itemDiscountPrice
              ? item.itemPrice - item.itemDiscountPrice
              : 0) * item.quantity
        )
        .reduce((a, b) => a + b, 0)
    )

    setTotalAmount(cartItemContent.map(item => item.itemPrice * item.quantity).reduce((a, b) => a + b, 0))
  }, [cartItemContent, shippingCost])

  const handleSetCouponValue = event => {
    setCouponCode(event.target.value)
  }

  const handleSubmitCoupon = event => {
    event.preventDefault()
    setDisabledBtnCoupon(true)
    if (couponCode) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/apply-coupone`,
          {
            code: couponCode,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtForProfile}`,
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(res => {
          setDisabledBtnCoupon(false)
          enqueueSnackbar(res.data.message, {
            variant: "success",
            autoHideDuration: 3000,
          })
          // setOpenAlertCouponSuccess({ isOpen: true, message: res.data.message })
          fetchCartData()
        })
        .catch(err => {
          setDisabledBtnCoupon(false)
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          })
          setErrorMassege({
            couponCode: true,
            couponCodeText: err.response.data.message,
          })
        })
    } else {
      setDisabledBtnCoupon(false)
      enqueueSnackbar(t("checkout.emptyCoupon"), {
        variant: "error",
        autoHideDuration: 3000,
      })
      setErrorMassege({
        couponCode: true,
        couponCodeText: t("checkout.emptyCoupon"),
      })
    }
  }

  const handleInfoForm = e => {
    if (
      checkoutForm.firstName &&
      checkoutForm.lastName &&
      checkoutForm.emailAddress &&
      checkoutFormPhone &&
      checkoutFormPhone.length > 8 &&
      checkoutForm.country &&
      checkoutForm.city &&
      checkoutForm.state &&
      checkoutForm.yourAddress &&
      checkoutForm.zipCode &&
      emailRegExp.test(checkoutForm.emailAddress)
    ) {
      // setDisabledBtn(true)
      // setIsSubmitted(true)
      // setActiveStep(activeStep + 1)
    } else {
      setIsSubmitted(true)
      setActiveStep(activeStep)
      enqueueSnackbar(t("checkout.wrongEntry"), { variant: "error", autoHideDuration: 3000 })
      let errorObject
      for (let key in checkoutForm) {
        if (!checkoutForm[key] && [key] != "yourNotes") {
          errorObject = {
            ...errorObject,
            [key]: true,
            [key + "Text"]: t("errors.requiredField"),
          }
        }
      }
      if (!checkoutFormPhone || checkoutFormPhone.length > 8) {
        errorObject = {
          ...errorObject,
          phoneNumber: true,
          phoneNumberText: t("errors.requiredField"),
        }
      }
      setErrorMassege({ ...errorMassege, ...errorObject })
    }
  }

  const handlePaymentForm = e => {
    if (payType === "credit_card") {
      setDisabledBtn(true)
      if (
        checkoutPaymentForm.cardName &&
        checkoutPaymentForm.cardNumber &&
        checkoutPaymentForm.cardCVV &&
        checkoutPaymentForm.cardEndMonth &&
        checkoutPaymentForm.cardEndYear
      ) {
        setDisabledBtn(false)
        validateFormChild.current.submit()
        setActiveStep(activeStep)
      } else {
        setActiveStep(activeStep)
        enqueueSnackbar(t("checkout.wrongEntry"), { variant: "error", autoHideDuration: 3000 })
        let errorObject
        for (let key in checkoutPaymentForm) {
          if (!checkoutPaymentForm[key] && [key] != "yourNotes") {
            errorObject = {
              ...errorObject,
              [key]: true,
              [key + "Text"]: t("errors.requiredField"),
            }
          }
        }
        setErrorMassege({ ...errorMassege, ...errorObject })
        setDisabledBtn(false)
      }
    } else {
      setActiveStep(activeStep + 1)
      axios
        .post(
          `${process.env.GATSBY_API_URL}/create-and-pay-order`,
          {
            cartType: "paper",
            payType: payType,
            buySource: "site",
            address: {
              country: checkoutForm.country,
              city: checkoutForm.city,
              addressText: checkoutForm.yourAddress,
              zip: checkoutForm.zipCode,
              // addressTitle: checkoutForm.addressTitle,
              state: checkoutForm.state,
              notes: checkoutForm.yourNotes,
            },
            contactInfo: {
              name: checkoutForm.firstName + checkoutForm.lastName,
              phone: checkoutFormPhone,
              email: checkoutForm.emailAddress,
            },
            cardName: checkoutForm.cardName,
            cardNumber: checkoutForm.cardNumber,
            cardCVV: checkoutForm.cardCVV,
            cardEndMonth: checkoutForm.cardEndMonth,
            cardEndYear: checkoutForm.cardEndYear,
          },
          { headers: { Authorization: `Bearer ${jwtForProfile}` } }
        )
        .then(res => {
          enqueueSnackbar(t("checkout.orderCompleted"), { variant: "success", autoHideDuration: 3000 })
          setDisabledBtn(false)
          setActiveStep(activeStep + 1)
          fetchCartData()
          setTimeout(() => {
            payType === "whatsapp" &&
              // ?
              (windowGlobal.location.href = `https://wa.me/905418983688?text=${t("checkout.orderNumber")}${
                res.data.orderID
              }`)
            // : navigate("/")
          }, 3500)
        })
    }
  }

  function getWizardStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <WizardStepContent cartItemContent={cartItemContent} setCartItemContent={setCartItemContent} />
          </>
        )
      case 1:
      case 2:
        return (
          <CheckoutWzard
            step={step}
            pLang={pLang}
            payType={payType}
            setPayType={setPayType}
            isSubmitted={isSubmitted}
            checkoutForm={checkoutForm}
            errorMassege={errorMassege}
            paymentOptions={paymentOptions}
            cartItemContent={cartItemContent}
            setCheckoutForm={setCheckoutForm}
            setErrorMassege={setErrorMassege}
            validateFormChild={validateFormChild}
            checkoutFormPhone={checkoutFormPhone}
            setCartItemContent={setCartItemContent}
            checkoutPaymentForm={checkoutPaymentForm}
            setCheckoutFormPhone={setCheckoutFormPhone}
            setCheckoutPaymentForm={setCheckoutPaymentForm}
          />
        )
      case 3:
        return t("cartItems.successMessage")
      default:
        return "Unknown step"
    }
  }

  return (
    <>
      <SEO title={t("cartItems.pageTitle")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("cartItems.pageTitle")} image={ShoppingCartIcon} />

      {/* Signed in content */}
      <Box component="div" display={jwtForProfile ? `block` : `none`}>
        <Container className={classes.mainContainer}>
          {cartItemContent.length !== 0 ? (
            <>
              <Paper className={`${classes.paperShadow}`}>
                {/* <form
                  ref={validateForm}
                  // id="validateForm"
                  method="post"
                  // onSubmit={handleSubmitForm()}
                  action={`${payType === "credit_card" ? "https://gwthani.com/sendPayment.php" : ""}`}
                > */}
                <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper} ref={stepperRef}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent TransitionProps={{ unmountOnExit: false }}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={8} sm={12}>
                            {getWizardStepContent(index)}
                          </Grid>

                          <Grid item xs={12} md={4} sm={12}>
                            {activeStep === 0 ? (
                              <Paper className={`${classes.sideBar} ${classes.paperShadow}`}>
                                <div className={`${classes.totalDataSideBar}`}>
                                  <div className={classes.orderPriceDetails}>
                                    <Typography variant="h5" component="p" className={classes.orderTotalPrice}>
                                      {t("cartItems.total")}
                                      <br />
                                      {parseFloat(totalPrice * currencyRateState).toFixed(2)}
                                      {currencyIcon}
                                    </Typography>
                                    <TableContainer>
                                      <Table className={classes.table} size="small" aria-label="a book price table">
                                        <TableBody>
                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              {t("cartItems.subTotal")}
                                            </TableCell>
                                            <TableCell align="right">
                                              {parseFloat(totalAmount * currencyRateState).toFixed(2)}
                                              {currencyIcon}
                                            </TableCell>
                                          </TableRow>

                                          {discountPrice > 0 && (
                                            <TableRow>
                                              <TableCell component="th" scope="row">
                                                {t("checkout.discount")}
                                              </TableCell>
                                              <TableCell align="right">
                                                {parseFloat(discountPrice * currencyRateState).toFixed(2)}
                                                {currencyIcon}
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                  <Divider variant="fullWidth" component="hr" />
                                  <div className={classes.couponForm}>
                                    <Box /* onSubmit={handleSubmitCoupon} */>
                                      <Typography
                                        variant="subtitle1"
                                        component="h3"
                                        className={classes.couponFormTitle}
                                      >
                                        {t("checkout.couponTitle")}
                                      </Typography>
                                      <FormControl fullWidth className={`${classes.couponFormConrol}`}>
                                        <TextField
                                          id="coupon"
                                          name="coupon"
                                          type="text"
                                          size="small"
                                          label={t("checkout.couponTitle")}
                                          variant="outlined"
                                          onChange={handleSetCouponValue}
                                          error={errorMassege.couponCode}
                                          helperText={errorMassege.couponCodeText}
                                        />
                                      </FormControl>
                                      <ButtonGroup
                                        // color="primary"
                                        aria-label="Order"
                                        disabled={disabledBtnCoupon}
                                        onClick={handleSubmitCoupon}
                                        className={classes.orderCouponButton}
                                        fullWidth={true}
                                      >
                                        <Button
                                          type="button"
                                          variant="contained"
                                          className={classes.orderBookBtn}
                                          startIcon={<LocalOfferIcon />}
                                        >
                                          {disabledBtnCoupon && <CircularProgress size={26} />}
                                          {!disabledBtnCoupon && t("checkout.applyCoupon")}
                                        </Button>
                                      </ButtonGroup>
                                    </Box>
                                  </div>
                                </div>
                              </Paper>
                            ) : activeStep === 1 || activeStep === 2 ? (
                              <Paper className={`${classes.sideBar} ${classes.paperShadow}`}>
                                <div className={`${classes.totalDataSideBar}`}>
                                  <div className={classes.orderSideBarBooks}>
                                    <Typography variant="h5" component="p" className={classes.orderTotalPrice}>
                                      {t("cartItems.orderSummary")}
                                    </Typography>
                                    <br />
                                    {cartItemContent.map(item => (
                                      <Box key={item.id}>
                                        <Grid container>
                                          <Grid item xs={3}>
                                            <Link to={`/${i18n.language}/books/${item.book.bookID}`}>
                                              {item.book.image ? (
                                                <img
                                                  src={`${process.env.GATSBY_API_URL}${item.book.image.url}`}
                                                  alt="book"
                                                  className={classes.checkoutBookImageSideBar}
                                                />
                                              ) : (
                                                <img
                                                  src={BookImage}
                                                  alt="book"
                                                  className={classes.checkoutBookImageSideBar}
                                                />
                                              )}
                                            </Link>
                                          </Grid>
                                          <Grid item xs={9}>
                                            <Link
                                              to={`/${i18n.language}/books/${item.book.bookID}`}
                                              className={classes.orderSideBarBooksLinks}
                                            >
                                              <Typography
                                                component="h3"
                                                variant="body1"
                                                className={classes.orderSideBarBooksTitle}
                                              >
                                                {item.book.bookTitle}
                                              </Typography>
                                            </Link>
                                            <div className={classes.orderSideBarBooksPriceContainer}>
                                              {item.quantity && (
                                                <Typography component="span">{item.quantity} x </Typography>
                                              )}
                                              {/* couponeDiscountedPrice */}
                                              {item.couponeDiscountedPrice ? (
                                                <Typography component="p">
                                                  {parseFloat(item.couponeDiscountedPrice * currencyRateState).toFixed(2)} {currencyIcon}
                                                </Typography>
                                              ) : item.discountPrice ? (
                                                <Typography component="p">
                                                  {parseFloat(item.discountPrice * currencyRateState).toFixed(2)} {currencyIcon}
                                                </Typography>
                                              ) : (
                                                <Typography component="p">
                                                  {parseFloat(item.itemPrice * currencyRateState).toFixed(2)} {currencyIcon}
                                                </Typography>
                                              )}
                                            </div>
                                          </Grid>
                                        </Grid>
                                        <Divider />
                                        <br />
                                      </Box>
                                    ))}
                                  </div>
                                  <div className={classes.orderPriceDetails}>
                                    {/* <Typography variant="h5" component="p" className={classes.orderTotalPrice}>
                                      {t("cartItems.orderSummary")}
                                      <br />
                                      {totalPrice.toFixed(2)}
                                      {currencyIcon}
                                    </Typography> */}
                                    <TableContainer>
                                      <Table className={classes.table} size="small" aria-label="a book price table">
                                        <TableBody>
                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              {t("cartItems.subTotal")}
                                            </TableCell>
                                            <TableCell align="right">
                                              {parseFloat(totalAmount * currencyRateState).toFixed(2)}
                                              {currencyIcon}
                                            </TableCell>
                                          </TableRow>
                                          {discountPrice > 0 && (
                                            <TableRow>
                                              <TableCell component="th" scope="row">
                                                {t("checkout.discount")}
                                              </TableCell>
                                              <TableCell align="right">
                                                {parseFloat(discountPrice * currencyRateState).toFixed(2)}
                                                {currencyIcon}
                                              </TableCell>
                                            </TableRow>
                                          )}
                                          {shippingCost > 0 && (
                                            <TableRow style={{ backgroundColor: "#f1f1f1" }}>
                                              <TableCell component="th" scope="row">
                                                {t("cartItems.shippingCost")}
                                              </TableCell>

                                              <TableCell align="right">
                                                {parseFloat(shippingCost * currencyRateState).toFixed(2)}
                                                {currencyIcon}
                                              </TableCell>
                                            </TableRow>
                                          )}
                                          <TableRow>
                                            <TableCell component="th" scope="row">
                                              {t("cartItems.allTotal")}
                                            </TableCell>
                                            <TableCell align="right">
                                              {parseFloat(totalPrice * currencyRateState).toFixed(2)}
                                              {currencyIcon}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                    <br />
                                    {!shippingCost && (
                                      <Alert severity="warning">{t("cartItems.shipingCostInNext")}</Alert>
                                    )}
                                  </div>
                                  <Divider variant="fullWidth" component="hr" />
                                </div>
                              </Paper>
                            ) : (
                              ""
                            )}

                            <div className={classes.stepperActionButtons}>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBackStep}
                                fullWidth={true}
                                className={classes.button}
                              >
                                {t("cartItems.back")}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate(`/${i18n.language}/checkout`)}
                                className={classes.button}
                                fullWidth={true}
                                disabled={disabledBtn ? disabledBtn : activeStep === steps.length - 1 ? true : false}
                              >
                                {disabledBtn && <CircularProgress size={26} />}
                                {!disabledBtn && activeStep === 2 ? t("orderBook.orderBookBtn") : t("cartItems.next")}
                                {/* {} */}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {/* </form> */}
              </Paper>
            </>
          ) : loadingContent ? (
            <Typography variant="subtitle1">{t("myProfile.loading")}</Typography>
          ) : (
            t("cartItems.noContent")
          )}
        </Container>
      </Box>
      {/* Not signed in content */}
      <Box component="div" display={!jwtForProfile ? `block` : `none`}>
        <Container className={classes.notSignedIn}>
          <Typography variant="subtitle1">{t("myProfile.noPremissions")}</Typography>
          <br />
          <Sigin />
        </Container>
      </Box>
    </>
  )
}
export default CartItems

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  cartItemsTable: {
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  bookImageMobile: {
    maxWidth: "120px",
    width: "100%",
  },
  BookTitleBtnsInner: {
    height: "100%",
    position: "relative",
    minHeight: 150,
    "& a": {
      textDecoration: "none",
    },
  },
  BookDeleteItemBtn: {
    position: "absolute",
    left: 0,
    bottom: 20,
    "& button": {
      borderRadius: 10,
    },
  },
  continueBtn: {
    borderRadius: 10,
    paddingRight: 40,
    paddingLeft: 40,
  },
  navigateInCartBtns: {
    marginTop: "3rem",
    textAlign: "center",
  },
  cartItemTbodyTh: {
    width: "calc(100% - 47%)",
  },
  checkoutBtn: {
    backgroundColor: "#003e96",
    borderRadius: 10,
    color: "#fff",
    paddingRight: 40,
    paddingLeft: 40,
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#065dd8",
    },
  },
  cartItemsTableText: {
    fontSize: 18,
    fontWeight: 600,
  },
  cartItemsTableTextHeader: {
    fontSize: 18,
  },
  textRight: {
    textAlign: "right",
  },
  BookDeleteItemBtnMobile: {
    marginTop: "1rem",
    "& button": {
      borderRadius: 10,
    },
  },
  cartItemDetails: {
    marginTop: theme.spacing(1),
  },
  cartItemDetailsTitle: {
    color: "#003e96",
    fontWeight: "600",
  },
  notSignedIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "26vh",
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  bookQuantityMobile: {
    display: "inline-block",
    marginLeft: theme.spacing(1),
    "& .MuiButtonBase-root": {
      padding: "4px 15px",
    },
  },
  bookQuantityBtnsMobile: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  tableRow: {
    borderBottom: "2px solid Gray",
  },
  tableRowMobile: {
    marginBottom: 16,
  },
  favButton: {
    display: "inline",
    "& img": {
      maxWidth: 24,
    },
    "&>button": {
      marginLeft: 6,
      padding: "0px 20px",
    },
  },
  // New styles

  orderItemPrice: {
    color: "#3f51b5",
    fontSize: "1.25rem",
  },
  orderItemGridContainer: {
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: "1px solid #dcdcdc",
  },
  bookImage: {
    height: 180,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
    },
  },
  oredrItemDetails: {
    padding: 3,
    maxWidth: 370,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "1rem",
    paddingBottom: "1rem",
    "& a": {
      textDecoration: "none",
    },
    "& a *": {
      color: "#4a4a4a",
      fontWeight: 600,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingLeft: "initial",
    },
  },
  orderItemQuantitySection: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      alignItems: "stretch",
    },
  },
  cartItemsCountPaper: {
    maxWidth: "70%",
    borderRadius: 4,
    margin: "10px auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  orderItemQuantityBtns: {
    padding: "8px 10px",
  },
  stepper: {
    // padding: 0,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 4,
    },
  },
  totalDataSideBar: {
    color: "#505050",
    margin: "0 auto",
    fontFamily: "'Tajawal' !important",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  sideBar: {
    padding: "16px",
  },
  orderTotalPrice: {
    textAlign: "center",
  },
  table: {
    color: "#fff",
    marginTop: 14,
    "& .MuiTableCell-root": {
      color: "#505050",
      padding: ".5rem",
      fontWeight: "600",
      textAlign: "left",
      border: "1px solid rgba(224, 224, 224, 1)",
    },
  },
  couponForm: {
    marginTop: 14,
  },
  couponFormTitle: {
    fontWeight: 600,
    marginBottom: 2,
  },
  orderCouponButton: {
    marginTop: 14,
  },
  orderPriceDetails: {
    marginBottom: 24,
  },
  "@global": {
    body: {
      backgroundColor: "#fff",
    },
  },
  mainContainer: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(9),
  },
  paperShadow: {
    boxShadow: "0px 1px 3px -2px rgb(0 0 0 / 20%), 0px 1px 9px 0px rgb(0 0 0 / 12%)",
  },
  stepperActionButtons: {
    marginTop: 10,
  },
  checkoutBookImageSideBar: {
    maxWidth: "90%",
    maxHeight: "90%",
  },
  orderSideBarBooksTitle: {
    color: "#4b4b4b",
    fontWeight: 500,
    fontSize: "0.875rem",
    textDecoration: "none",
  },
  orderSideBarBooksPriceContainer: {
    marginTop: 8,
    "& p": {
      color: "green",
      display: "inline-block",
    },
  },
  orderSideBarBooksLinks: {
    textDecoration: "none",
  },
}))

function ButtonLink(props) {
  const { icon, primary, to } = props
  const classes = useStyles()

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to]
  )

  return (
    <Button
      component={renderLink}
      className={classes.checkoutBtn}
      activeClassName={classes.activeMenu}
      startIcon={icon}
    >
      {primary}
    </Button>
  )
}

ButtonLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document
